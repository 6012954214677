@font-face {
  font-family: 'Barokah Signature';
  src: url('./fonts/BarokahSignature-Regular.eot');
  src: url('./fonts/BarokahSignature-Regular.eot?#iefix') format('embedded-opentype'),
    url('./fonts/BarokahSignature-Regular.woff2') format('woff2'),
    url('./fonts/BarokahSignature-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bebas Neue';
  src: url('./fonts/BebasNeue-Light.eot');
  src: url('./fonts/BebasNeue-Light.eot?#iefix') format('embedded-opentype'),
    url('./fonts/BebasNeue-Light.woff2') format('woff2'),
    url('./fonts/BebasNeue-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bebas Neue';
  src: url('./fonts/BebasNeue-Regular.eot');
  src: url('./fonts/BebasNeue-Regular.eot?#iefix') format('embedded-opentype'),
    url('./fonts/BebasNeue-Regular.woff2') format('woff2'),
    url('./fonts/BebasNeue-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bebas Neue';
  src: url('./fonts/BebasNeue-Bold.eot');
  src: url('./fonts/BebasNeue-Bold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/BebasNeue-Bold.woff2') format('woff2'),
    url('./fonts/BebasNeue-Bold.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

/* 
@font-face {
  font-family: 'LEMON MILK';
  src: url('./fonts/LEMONMILK-Light.eot');
  src: url('./fonts/LEMONMILK-Light.eot?#iefix') format('embedded-opentype'),
    url('./fonts/LEMONMILK-Light.woff2') format('woff2'),
    url('./fonts/LEMONMILK-Light.woff') format('woff'),
    url('./fonts/LEMONMILK-Light.ttf') format('truetype'),
    url('./fonts/LEMONMILK-Light.svg#LEMONMILK-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'LEMON MILK';
  src: url('./fonts/LEMONMILK-Regular.eot');
  src: url('./fonts/LEMONMILK-Regular.eot?#iefix') format('embedded-opentype'),
    url('./fonts/LEMONMILK-Regular.woff2') format('woff2'),
    url('./fonts/LEMONMILK-Regular.woff') format('woff'),
    url('./fonts/LEMONMILK-Regular.ttf') format('truetype'),
    url('./fonts/LEMONMILK-Regular.svg#LEMONMILK-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'LEMON MILK';
  src: url('./fonts/LEMONMILK-Medium.eot');
  src: url('./fonts/LEMONMILK-Medium.eot?#iefix') format('embedded-opentype'),
    url('./fonts/LEMONMILK-Medium.woff2') format('woff2'),
    url('./fonts/LEMONMILK-Medium.woff') format('woff'),
    url('./fonts/LEMONMILK-Medium.ttf') format('truetype'),
    url('./fonts/LEMONMILK-Medium.svg#LEMONMILK-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'LEMON MILK';
  src: url('./fonts/LEMONMILK-Bold.eot');
  src: url('./fonts/LEMONMILK-Bold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/LEMONMILK-Bold.woff2') format('woff2'),
    url('./fonts/LEMONMILK-Bold.woff') format('woff'),
    url('./fonts/LEMONMILK-Bold.ttf') format('truetype'),
    url('./fonts/LEMONMILK-Bold.svg#LEMONMILK-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Brittany Signature';
  src: url('./fonts/BrittanySignatureRegular.eot');
  src: url('./fonts/BrittanySignatureRegular.eot?#iefix') format('embedded-opentype'),
    url('./fonts/BrittanySignatureRegular.woff2') format('woff2'),
    url('./fonts/BrittanySignatureRegular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
} */
.shine {
  position: relative;
  overflow: hidden;
}

.shine::before {
  background: linear-gradient(to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.3) 100%);
  content: '';
  display: block;
  height: 100%;
  left: -85%;
  position: absolute;
  top: 0;
  transform: skewX(-25deg);
  width: 50%;
  z-index: 2;
}

.shine:hover::before,
.shine:focus::before {
  animation: shine 0.85s;
}

.customCaption .stepped_title {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.customCaption .stepped_title span {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  position: relative;
  transform: translateX(calc(100px * var(--step, 0)));
  background-color: #f78a6d9c;
  max-width: max-content;
  padding: 12px;
  transition: all 0.3s;
  cursor: default;
  gap: 20px;
  border: 2px solid white;
  min-width: 336px;
  color: #fff;
}

.customCaption .stepped_title span::before {
  content: '';
  width: 20px;
  height: 20px;
  background-color: gray;
  border-radius: 50%;
  display: flex;
  transition: all 0.3s;
}

.customCaption .stepped_title span:hover {
  background-color: gray;
}

.customCaption .stepped_title span:hover::before {
  background-color: var(--orange);
}

.customCaption.noBorder {
  border: none;
}

@keyframes shine {
  100% {
    left: 125%;
  }
}

.mobileSitckyBtn {
  display: none;
}

.whatsAppBtnDiv {
  width: 60px;
  background: transparent;
  border: none;
}

.whatsAppBtn {
  display: none;
}

.whatsAppBtnlg {
  display: flex;
  flex-direction: row;
  position: fixed;
  width: 170px;
  left: 20px;
  z-index: 9;
  bottom: 20px;
  background-color: #27b43e;
  border-radius: 50px;
  border: none;
}

.whatsAppBtnlg p {
  color: white;
  margin: auto;
  margin-right: 10px;
  font-weight: 700;
  letter-spacing: 1px;
}

@media screen and (max-width: 768px) {
  .get {
    font-size: 12px;
    line-height: normal !important;
    padding-top: 10px;
  }

  .mobileSitckyBtn {
    display: block;
    position: fixed;
    right: 55px;
    z-index: 4;
    bottom: 120px;
  }

  .whatsAppBtn {
    display: block;
    position: fixed;
    left: 20px;
    z-index: 4;
    bottom: 20px;
    width: inherit;
  }

  .whatsAppBtnlg {
    display: none;
  }

  .menu {
    position: relative;
    margin-top: 15%;
    left: 50%;
    width: 0px;
    z-index: 1000000000000;
    transform: translate(-50%, 55%);
  }

  .toggle {
    display: none;
  }

  .toggle+.style {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    cursor: pointer;
    transform: translate(-50%, -50%) scale(1);
    z-index: 5;
    display: block;
    margin-bottom: 0;
    background: #1d1d1d;
    border: 1px #676767 solid;
    color: #fff;
    font-size: 2.5em;
    text-align: center;
    -webkit-transition: all 0.8s;
    -moz-transition: all 0.8s;
    -o-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s;
    line-height: 52px;
  }

  .menu p {
    max-width: 85px;
    text-align: center;
    margin: 0 auto;
    line-height: 15px;
    padding-top: 50px;
    font-weight: 700;
    font-size: 2em;
  }

  .toggle+.style:hover {
    -webkit-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.7);
    -moz-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.7);
    -ms-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.7);
    -o-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.7);
    box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.7);
  }

  .toggle:checked+.style {
    transform: translate(-50%, -50%) scale(1);
    background: #3e3e3e;
  }

  .toggle:checked+.style svg {
    transform: rotate(45deg) !important;
  }

  .menu input[type='checkbox']+label:before {
    content: '';
    position: absolute;
  }

  .toggle+.style:before,
  .toggle+.style:after {
    content: '';
    position: absolute;
    margin: -3px;
    border-radius: 5px;
    transition: all 0.3s;
  }

  .toggle+.style:before {
    width: 30px;
    height: 0px;
    left: 10px;
    top: 25px;
  }

  .toggle+.style:after {
    width: 0px;
    height: 30px;
    left: 25px;
    top: 10px;
  }

  .toggle:checked+.style:before,
  .toggle:checked+.style:after {
    transform: rotate(135deg);
  }

  .toggle~.tab {
    position: absolute;
    background: #1d1d1d;
    color: #fff;
    width: 56px;
    height: 56px;
    left: 0px;
    top: 0px;
    transform: translate(-50%, -50%) scale(0);
    transition: all 0.3s;
    opacity: 0;
    text-align: center;
    border-radius: 50%;
    line-height: 56px;
    border: 1px #676767 solid;
  }

  .toggle:checked~.tab {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }

  .menu>.toggle:checked~.tab:nth-of-type(1) {
    top: -74px;
    left: 0px;
    transition-delay: 0s;
  }

  .menu>.toggle:checked~.tab:nth-of-type(2) {
    top: -34px;
    left: -64px;
    transition-delay: 0.125s;
  }

  .menu>.toggle:checked~.tab:nth-of-type(3) {
    top: 34px;
    left: -64px;
    transition-delay: 0.25s;
  }

  .menu>.toggle:checked~.tab:nth-of-type(4) {
    top: 122px;
    left: 0px;
    transition-delay: 0.375s;
  }

  .menu>.toggle:checked~.tab:nth-of-type(5) {
    top: 56px;
    left: -115px;
    transition-delay: 0.5s;
  }

  .menu>.toggle:checked~.tab:nth-of-type(6) {
    top: -74px;
    left: -120px;
    transition-delay: 0.625s;
  }

  .menu>.toggle:checked~.tab:nth-of-type(7) {
    top: 0px;
    left: -133.33333px;
    transition-delay: 0.75s;
  }

  .menu>.toggle:checked~.tab:nth-of-type(8) {
    top: -94.2809px;
    left: -94.2809px;
    transition-delay: 0.875s;
  }

  .get {
    font-size: 12px;
    line-height: 14px !important;
    padding-top: 12px;
  }
}

@media (min-width: 1200px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1278px;
  }
}

@media (min-width: 1400px) {}

@media (min-width: 1920px) {}

.shineNew {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.shineNew img {
  width: 100%;
}

.shineNew:before {
  content: '';
  position: absolute;
  top: 0;
  z-index: 1000000;
  left: -100px;
  width: 70px;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  transform: skewX(-30deg);
  animation-name: slide;
  animation-duration: 7s;
  animation-timing-function: ease-in-out;
  animation-delay: 0.3s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  background: linear-gradient(to right,
      rgba(255, 255, 255, 0.13) 0%,
      rgba(255, 255, 255, 0.13) 77%,
      rgba(255, 255, 255, 0.5) 92%,
      rgba(255, 255, 255, 0) 100%);
}

@keyframes slide {
  0% {
    left: -100;
    top: 0;
  }

  50% {
    left: 120px;
    top: 0px;
  }

  100% {
    left: 290px;
    top: 0;
  }
}